<template>
    <!-- Add Dialog -->

    <generic-modal-wrapper ref="modal" max-width="900" :fullScreen="true" :interceptCloseButton="true" @modalOpen="modalOpen" @modalClose="modalClose" @beforeClose="onBeforeClose">
        <template #default="{ showModal }">
            <v-row v-if="!isInsideJobEventModal"  no-gutters class="justify-center mr-2">
                <v-btn :outlined="!hasProblems" :block="!$vuetify.breakpoint.smAndUp" class="elevation-0" :color="hasProblems ? 'error': 'primary'" @click.native.stop="() => {reloadData(); showModal()}" data-cy="custom-field-areas-modal-button">
                    <v-icon left>{{ mdiCrop() }}</v-icon>
                    Pindalatüübid {{hasProblems ? '('+problemCount+')' : ''}}
                </v-btn>
            </v-row>
            <v-row v-else no-gutters class="justify-center mx-3">
                <v-btn
                       color="primary"
                       class="mx-0 px-2 px-sm-4 my-2"
                       block
                       data-cy="navigate-to-custom-areas-management"
                       @click.native.stop="() => {reloadData(); showModal()}">
                    <v-icon class="mr-2">{{ mdiCrop() }}</v-icon>
                    Pindalatüübid
                </v-btn>
            </v-row>
        </template>
        <template #title>
            Pindalatüübid
        </template>
        <template #content>
            <v-row no-gutters>
                <v-col class="mb-6 mb-md-0 pr-0 pr-sm-8">
                    Pindalatüüpide muutmine <strong>ei mõjuta olemasolevaid töökandeid</strong> ja nende pindala väärtuseid kasutatakse ainult uute töökande lisamise hetkel, et määrata töökande pindala.
                </v-col>
                <v-col class="col-12 col-sm-4">
                    <v-select
                        :items="fieldYears"
                        v-model="yearModel"
                        prepend-icon="date_range"
                        no-data-text="Ühtegi"
                        label="Saagiaasta"
                        data-cy="field-year-select"
                    ></v-select>
                </v-col>
            </v-row>
            <custom-field-areas-add-modal ref="customFieldAreasModal" @customFieldAreaAdded="reloadData" :year="yearModel" />
            <v-data-table
                class="elevation-0 cropsRotation field-custom-areas firstColumnSticky mb-16 mt-6"
                :headers="headers"
                :items="customAreas"
                mobile-breakpoint="0"
                hide-default-footer
                :items-per-page=1000
            >
                <template v-for="(header, index) in headers" v-slot:[`header.${header.value}`]="">
                    <div :key="index" class="d-inline mr-2 align-center">
                        <span class="mr-3">{{ header.text }}</span>
                        <template v-if="header.customFieldAreaId" class="ml-2">
                            <editButton title="Redigeeri" :clickAction="() => editCustomFieldArea(header.customFieldAreaId)" data-cy="edit-custom-area-configuration-button"></editButton>
                            <deleteButton title="Kustuta pindalatüüp" :clickAction="() => deleteCustomFieldArea(header.text, header.customFieldAreaId)"  data-cy="delete-custom-area-configuration-button"/>
                        </template>
                    </div>
                </template>
                <template v-slot:item="{ item }">
                    <tr style="min-height: 100%; height: 100%;">
                        <td class="pl-0 pt-2 align-content-center" style="vertical-align: center; height: 100%;"
                            @click="openField(item.fieldId)" >
                            <v-col class="flex-grow-1">
                                <v-row no-gutters>
                                    <span class="text-subtitle-1 font-weight-bold linkLike primary--text">{{ item.fieldName }}</span>
                                </v-row>
                                <v-row no-gutters v-if="priaFieldCode(item.fieldId)">
                                    <span style="margin-top:-4px;font-size: .7rem !important" :style="item.active || 'color: #808080'">Põllu nr: {{ priaFieldCode(item.fieldId) }}</span>
                                </v-row>
                            </v-col>
                        </td>
                        <td style="padding: 0 0; min-height: 100%" :style="'width: '+ (85/(item.customFieldAreas.length+1))+'%'" class="pl-0 pl-md-3">
                            {{item.actualArea | toLocalRoundedNumber}} ha
                        </td>
                        <td v-bind:key="item._id+customFieldArea._id"
                            v-for="customFieldArea in item.customFieldAreas" style="padding: 0 0; min-height: 100%; min-width: 180px; vertical-align: top" :style="'width: '+ (85/(item.customFieldAreas.length+1))+'%'"  class="px-0 px-md-2 py-1">
                            <custom-field-areas-input 
                                ref="areaInputs"
                                :key="item.fieldId + '_' + customFieldArea._id"
                                :year="yearModel" 
                                :customFieldAreaId="customFieldArea._id" 
                                :fieldId="item.fieldId" 
                                :initialArea="customFieldArea.area" 
                                :fieldDefaultArea="item.actualArea" 
                                :status="customFieldArea.status"
                                @change="trackChange">
                            </custom-field-areas-input>
                        </td>
                    </tr>
                </template>
                <template v-slot:no-data>
                    <v-subheader class="justify-center">
                        Esmalt lisage mõned põllud. Seda saab teha alamlehel
                        <router-link style="margin-left:5px" class="font-weight-bold" :to="{ name: 'pollud', params: currentYear}">Põlluraamat</router-link>
                    </v-subheader>
                </template>
            </v-data-table>
            <custom-field-areas-add-modal @customFieldAreaAdded="reloadData" :year="yearModel"  />
        </template>
        <template #footer="{ hideModal }">
            <v-btn color="primary" dark class="mx-2" @click.native="() => checkForUnsavedChanges(hideModal)" data-cy="custom-field-area-close-modal-button">Sulge</v-btn>
        </template>
    </generic-modal-wrapper>
</template>
<style scoped>
/* Target the header cells */

</style>
<script>
    import _ from "lodash"
    import {toLocalRoundedNumber} from "../../../backend/common/eventToTextHelper";
    import CustomFieldAreasInput from "@/fields/customFieldAreasInput.vue";
    import CustomFieldAreasAddModal from "@/fields/customFieldAreasAddModal.vue";
    import GenericModalWrapper from "@/components/genericModalWrapper.vue";
    import {mdiCrop} from "@mdi/js";
    import editButton from "@/components/editButton.vue";
    import deleteButton from "@/components/deleteButton.vue";
    import {findFieldYearInfo} from "../../../dist/common/fieldYearInfo.js"
    import {findCustomAreaYearlyAreaForYear} from "../../../backend/common/customAreaYearlyAreas";

    export default {
        props: {
            year: {
                type: Number
            },
            isInsideJobEventModal: { // TODO: full witdh vaade katki mobiilivaates
                type: Boolean,
                default: false
            },
            fullWidthButton: {
                type: Boolean,
                default: false
            }
        },
        components: {
            deleteButton,
            editButton,
            GenericModalWrapper,
            CustomFieldAreasAddModal,
            CustomFieldAreasInput,
        },
        data() {
            return {
                file: null,
                useFieldNumber: true,
                useComments: false,
                yearModel: this.year,
                unsavedChanges: [], // Track inputs with unsaved changes
            }
        },
        computed: {
            currentYear() {
                return this.$store.getters.getCurrentFullYear;
            },
            headers() {
                let headings = [];
                headings.push({
                    text: 'Põld',
                    align: 'left',
                    sortable: true,
                    value: 'fieldName'
                });
                headings.push({
                    text: this.yearModel + ' pindala',
                    align: 'left',
                    sortable: true,
                    value: 'actualArea'
                });
                if (this.customAreas && this.customAreas.length > 0) {
                    _.forEach(this.customAreas[0].customFieldAreas, (customFieldAreas, index) => {
                        headings.push({
                            text: customFieldAreas.areaName,
                            align: 'left',
                            sortable: true,
                            value: 'customFieldAreas['+index+'].area',
                            customFieldAreaId: customFieldAreas._id
                        })});
                }
                return headings;
            },
            customAreasData() {
                return this.$store.getters.getCustomAreas;
            },
            customAreas() {
                const fields = this.$store.getters.fields;
                const customAreasFromState = this.customAreasData
                return fields.map(field => {
                    const fieldYearInfo = findFieldYearInfo(field, this.yearModel);
                    return {
                        fieldId: field._id.toString(),
                        fieldName: fieldYearInfo.name,
                        actualArea: fieldYearInfo.area,
                        customFieldAreas: customAreasFromState.map(customFieldArea => {
                            const currentField = customFieldArea.fields.find(f => f.fieldId.toString() === field._id.toString());
                            const currentCustomAreas = findCustomAreaYearlyAreaForYear(currentField.yearlyAreas, this.yearModel);
                            return {
                                _id: customFieldArea._id,
                                areaName: customFieldArea.areaName,
                                area: currentCustomAreas.area,
                                areaWhenItWasSaved: currentCustomAreas.areaWhenItWasSaved,
                                status: currentCustomAreas.status
                            }
                        })
                    }
                });
            },
            problemCount() {
                return this.customAreas
                    .map(e => e.customFieldAreas.filter(f => f.status).length)
                    .reduce((a, b) => a + b, 0);
            },
            hasProblems() {
                return this.problemCount > 0;
            },
            fieldYears() {
                return this.$store.getters.getFieldYears;
            },
            hasUnsavedChanges() {
                return this.unsavedChanges.length > 0;
            },
        },
        methods: {
            mdiCrop() {
                return mdiCrop
            },
            toLocalRoundedNumber,
            modalOpen() {
                this.yearModel = this.year;
                this.unsavedChanges = []; // Reset unsaved changes tracker
                this.$emit('modalOpened');
            },
            modalClose() {
                this.unsavedChanges = []; // Reset unsaved changes tracker
                this.$emit('modalClosed');
            },
            async reloadData() {
                this.$store.dispatch('loadSettings');
            },
            openField(fieldId) {
                let currentFullYear = this.$store.getters.getCurrentFullYear;
                this.$router.push({ name: 'jobEvents', params: { id: fieldId, year: currentFullYear }})
            },
            deleteCustomFieldArea(customFieldAreaName, customFieldAreaId) {
                this.$confirm(`Olete kindel, et soovite <strong>kustutada</strong> pindalatüübi "<strong>${customFieldAreaName}</strong>"? <br/>Olemasolevaid töökandeid, mille loomisel kasutati seda pindalatüüpi, ei muudeta. <br />Uute töökannete lisamisel ei ole lihtsalt see pindalatüüp enam valitav.`,
                    {title:'Pindalatüübi kustutamine', buttonTrueText: 'Jah, kustuta!'})
                    .then(async (result) => {
                        if (result) {
                            try {
                                await this.$store.dispatch('removeCustomFieldArea', customFieldAreaId);
                                this.$store.dispatch('setSystemMessage', {text: "Edukalt kustutatud!"});
                                this.reloadData();
                            } catch (e) {
                                this.$store.dispatch('setSystemMessage', {text: "Kustutamine ebaõnnestus!", type:"ERROR"});
                            }
                        }
                    });
            },
            editCustomFieldArea(customFieldAreaId) {
                const customAreaToUpdate = this.customAreasData.find(f => f._id === customFieldAreaId);
                this.$refs.customFieldAreasModal.show(
                    {
                        customFieldAreaId: customFieldAreaId,
                        areaName: customAreaToUpdate.areaName,
                        calculationStrategy: customAreaToUpdate.calculationStrategy,
                        calculationValue: customAreaToUpdate.calculationValue,
                    }
                );
            },
            priaFieldCode(fieldId) {
                const currentField = this.$store.getters.field(fieldId);
                const fieldYearInfo = _.find(currentField.yearInfo, yearInfo => yearInfo.year === this.yearModel);
                if (fieldYearInfo.priaCode) {
                    return ' '+fieldYearInfo.priaCode
                } else {
                    return null
                }
            },
            // Track changes in the custom area inputs
            trackChange(input, hasChanges) {
                if (hasChanges) {
                    // Add input to unsaved changes if not already there
                    if (!this.unsavedChanges.includes(input)) {
                        this.unsavedChanges.push(input);
                    }
                } else {
                    // Remove input from unsaved changes
                    const index = this.unsavedChanges.indexOf(input);
                    if (index > -1) {
                        this.unsavedChanges.splice(index, 1);
                    }
                }
            },
            // Check for unsaved changes before closing modal
            checkForUnsavedChanges(hideModal) {
                if (this.hasUnsavedChanges) {
                    this.$confirm(
                        `Teil on salvestamata muudatusi. Kas soovite kõik muudatused salvestada või nendest loobuda?`,
                        { 
                            title: 'Salvestamata muudatused',
                            buttonTrueText: 'Salvesta kõik',
                            buttonFalseText: 'Loobu muudatustest'
                        }
                    ).then(result => {
                        if (result) {
                            // Save all changes
                            this.saveAllChanges();
                            hideModal();
                        } else {
                            // Discard all changes
                            this.discardAllChanges();
                            hideModal();
                        }
                    });
                } else {
                    hideModal();
                }
            },
            saveAllChanges() {
                const changesToSave = [...this.unsavedChanges];
                changesToSave.forEach(input => {
                    if (input && typeof input.updateCustomArea === 'function') {
                        input.updateCustomArea();
                    }
                });
                this.unsavedChanges = [];
            },
            discardAllChanges() {
                const changesToDiscard = [...this.unsavedChanges];
                
                // Process each input to revert changes
                changesToDiscard.forEach(input => {
                    if (input && typeof input.undo === 'function') {
                        try {
                            input.undo();
                        } catch (e) {
                            console.error("Error undoing change:", e);
                        }
                    }
                });
                
                // Clear the array after processing all changes
                this.unsavedChanges = [];
                this.$store.dispatch('setSystemMessage', {text: "Muudatustest loobutud!"});
            },
            onBeforeClose(hideModal) {
                this.checkForUnsavedChanges(hideModal);
            },
        },
        watch: {
            year: {
                handler: function (val) {
                    this.yearModel = val;
                },
                immediate: true
            }
        },
    }
</script>