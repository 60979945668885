<template>
    <div :class="manageFlexGrow ? 'col flex-grow-1 flex-sm-grow-0 pa-0' : null">
        <slot :showModal="() => showModal = true"/>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown || fullScreen" v-model="showModal" :max-width="maxWidth ? maxWidth : 550">
            <v-card>
                <v-card-title class="grey lighten-4 text-h6">
                    <v-btn color="primary" elevation="0" small right fab absolute class="closeButton"
                           @click="handleCloseButtonClick">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <slot name="title"/>
                </v-card-title>
                <info-modal-container-wrapper>
                    <slot name="content" />
                    <template #footer>
                        <slot name="footer" :hideModal="triggerCloseModal"/>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import infoModalContainerWrapper from "./infoModalContainerWrapper";

export default {
    props: {
        maxWidth: {
            type: String
        },
        fullScreen: {
            type: Boolean,
            default: false
        },
        manageFlexGrow: {
            type: Boolean,
            default: true
        },
        interceptCloseButton: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showModal: false,
    }),
    components: {
        infoModalContainerWrapper
    },
    computed: {},
    watch: {
        showModal() {
            if (this.showModal) {
                this.$emit('modalOpen');
            } else {
                this.$emit('modalClose');
            }
        }
    },
    methods: {
        triggerShowModal() {
            this.showModal = true;
        },
        handleCloseButtonClick() {
            if (this.interceptCloseButton) {
                this.$emit('beforeClose', this.triggerCloseModal);
            } else {
                this.triggerCloseModal();
            }
        },
        triggerCloseModal() {
            this.$emit('closeModal');
            this.showModal = false;
        },
    }
}
</script>